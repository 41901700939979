import { html } from 'lit-element';
import { styleMap } from 'lit-html/directives/style-map';

const ltext = {
  de: {
    modbusserver: 'Modbus Server',
    apm_system: 'APM System',
    apm_system_sim: 'APM System (Sim)',
    opcuaserver: 'OPC UA Server',
    mindsphere: 'Insights Hub Connector (MindSphere)',

    operatingstations: 'Bedienplätze',
    proto_prem: 'Protocol Premium',
    proto_std: 'Protocol Standard',
    everythingfromstd: 'Alle Treiber aus dem Standard-Paket und',
    ntcip: 'NTCIP',
    omronfins: 'Omron Fins',
    includes: 'beinhält',
    dashboardro: 'Dashboard Read-Only',
    uiclient: 'UI Client',
    uilight: 'UI Light',
    ulcwarn:
      'Für mehr als 30 ULCUX wird ein zusätzlicher Webserver für Load Balancing empfohlen. Die Anzahl für "ULC UX Loadbalancing" wurde auf die empfohlene Menge erhöht',
    sys: 'System',
    his: 'Archivierung',
    type: 'Art des Systems',
    name: 'Systemname',
    num: 'Anzahl',
    ios: 'Basis',
    loadbalancer: 'ULC-UX Loadbalancer',
    websocket: 'Websocket',
    sysios: 'System PowerTags',
    subios: 'PowerTags der Untersysteme',
    numios: 'Gesamt PowerTags',
    licios: 'Lizenz PowerTags',
    sysbacnets: 'System BACnet Objekte',
    subbacnets: 'BACnet Objekte Untersysteme',
    numbacnets: 'BACnet Objekte Gesamt',
    licbacnets: 'Lizenz BACnet Objekte',
    redundant: 'Redundant',
    dist: 'Verteilt',
    drs: 'DRS',
    drstooltip:
      'Disaster Recovery System. Ein DRS besteht aus 2 redundanten Systemen (in Summe 4 Server). Jeder Server benötigt eine eigene DRS Lizenz.',
    apmtooltip: 'Advanced Playback Manager',
    dongle: 'Dongle',
    dongleMicro: 'Dongle Micro',
    ui: 'Desktop UI',

    mobileui: 'Mobile UI',
    dashboard: 'Dashboard',
    dashboardrw: 'Dashboard Read/Write',

    dashboardmobile: 'Mobile Dashboard',
    ulcux: 'Webclient (ULC UX)',
    ulcuxtooltip:
      'UltraLightClient User eXperience Webclient. HTML5 kompatibler WinCC OA Webclient.',
    para: 'Engineering (Para)',
    hdb: 'intern (HDB / NGA Basis)',
    hdbtooltip:
      'Historical DataBase / Next Generation Archiver. Standard Archivierungslösung von WinCC OA.',
    rdb: 'Oracle (RDB)',
    rdbtooltip:
      'Relationale Databank Addon zur Anbindung einer Oracle Datenbank an einen WinCC OA Server.',
    nga: 'NGA',
    ngatooltip:
      'Next Generation Archiver. Archivierungslösung für WinCC OA für mehrere Datenbanken (optional).',
    ngastd: 'NGA Standard (zusätzlich zu NGA Basis)',
    ngaprem: 'NGA Premium',
    OA_IPC128: 'IPC 128',
    OA_IPC256: 'IPC 256',
    OA_IPC512: 'IPC 512',
    OA_IPC2048: 'IPC 2048',
    OA_IPC4096: 'IPC 4096',
    s7: 'S7+ (Verbindungen)',
    dnp: 'DNP3',
    dnptooltip: 'Distributed Network Protocol. Treiber für DNP3 Protokoll für Fernwirktechnik.',
    sinaut: 'SINAUT',
    videoworkstation: 'Video Workstation',
    videobasis: 'Video Basis',
    videolight: 'Video Light',
    sinauttooltip:
      'SIemens Network AUTomation Treiber, erlaubt die Verbindung mit Geräten über das SINAUT Protokoll.',
    drv: 'Konnektivität',
    OA: 'WinCC OA System',
    ODB: 'Oracle Datenbankserver',
    RP: 'Remote Engineering Station',
    modbus: 'Modbus TCP',
    profinet: 'PROFINET',
    profisafe: 'PROFIsafe',
    iec104: 'IEC 60870-5-104',
    iec101: 'IEC 60870-5-101',
    iec61850: 'IEC 61850/61400',
    saia: 'SAIA S-Bus',
    ssi: 'SSI',
    ssitooltip:
      'Synchrones Serielles Interface. Treiber für Ethernet Kommunikation via SAT Fernwirkkomponenten',
    tls: 'TLS',
    tlstooltip:
      'Treiber TLS (Technische Lieferbedingungen für Streckenstationen). Gateway Steuerung von Verkehrsbeeinflussungsanlagen.',
    rk512: 'RK512',
    snmp: 'SNMP',
    cerberus: 'Cerberus',
    cerberustooltip: 'Treiber zur Kopplung mit dem Siemens Brandmeldesystem DMS7000 /Cerberus.',
    eip: 'EthernetIP',
    bacnet: 'BACnet',
    mqtt: 'MQTT',
    opt: 'Addons',
    webserver: '# zusätzliche Webserver',
    ngaconnections: '# NGA Verbindungen (zusätzlich)',
    commcenter: 'CommCenter (Alarme)',
    CC0: 'Kein CommCenter',
    CC1: 'CommCenter 1 (25 Alarme)',
    CC2: 'CommCenter 2 (250 Alarme)',
    CC3: 'CommCenter 3 (2500 Alarme)',
    CC4: 'CommCenter 4 (unlimitiert)',
    customcomp: '# Custom Components',
    dblogger: 'DB Logger',
    gismap: 'GIS/Map',
    topology: 'Topologie Paket',
    modbussrv: 'Modbus Server',
    report: 'Report',
    ams: 'Advanced Maintenance Suite (AMS)',
    amstooltip: 'Advanced Maintenance Suite.',

    sscadakpi: 'SmartSCADA Toolbox (KPI)',
    sscadakpiext: 'SmartSCADA KPI Erweiterung',
    sscadaana: 'SmartSCADA Analytics',
    secure: 'Secure by Kerberos',
    videobase: 'Video Basis Paket',
    videosrc: 'Video (Kameras/Quellen)',
    V0: 'Kein Video',
    V1: '1 Quelle',
    V10: '10 Quellen',
    V25: '25 Quellen',
    V50: '50 Quellen',
    V100: '100 Quellen',
    V250: '250 Quellen',
    V500: '500 Quellen',
    videodisp: 'Video Displays',
    videodisplaydist: 'Video Displays DIST',
    asfu: 'App Specific Full Use (ASFU)',
    fu: 'Full Use (FU)',
  },
  en: {
    modbusserver: 'Modbus Server',
    opcuaserver: 'OPC UA Server',
    apm_system: 'APM System',
    apm_system_sim: 'APM System (Sim)',
    mindsphere: 'Insights Hub Connector (MindSphere)',
    operatingstations: 'Operating Stations',
    proto_prem: 'Protocol Premium',
    proto_std: 'Protocol Standard',
    ntcip: 'NTCIP',
    omronfins: 'Omron Fins',
    includes: 'includes',
    dashboardro: 'Dashboard Read-Only',
    dashboardrw: 'Dashboard Read/Write',
    uiclient: 'UI Client',
    uilight: 'UI Light',
    ulcwarn:
      'An additional web server for load balancing is recommended for more than 30 ULC UX. The number for "ULC UX Loadbalancing has been increas to the recommended amount.',
    sys: 'System',
    his: 'Archiving',
    type: 'Type of System',
    name: 'System name',
    num: '#Systems',
    loadbalancer: 'ULC-UX Loadbalancer',
    websocket: 'Websocket',
    ios: 'Base',
    sysios: 'System PowerTags',
    subios: 'PowerTags of the subsystems',
    numios: 'Total PowerTags',
    licios: 'Licensed PowerTags',
    sysbacnets: 'System BACnet Objects',
    subbacnets: 'BACnet Objects Subsystems',
    numbacnets: 'BACnet Objects Total',
    licbacnets: 'Licensed BACnet Objects',
    redundant: 'Redundant',
    dist: 'Distributed',
    drs: 'DRS',
    drstooltip:
      'Disaster Recovery System. A DRS consists of 2 redundant systems (in total 4 servers). Each server needs a DRS License.',
    apmtooltip: 'Advanced Playback Manager',
    dongle: 'Dongle',
    dongleMicro: 'Dongle Micro',
    ui: 'Desktop UI',

    mobileui: 'Mobile UI',
    dashboard: 'Dashboard',

    dashboardmobile: 'Mobile Dashboard',
    ulcux: 'Webclient (ULC UX)',
    ulcuxtooltip:
      'UltraLightClient User eXperience Webclient. HTML5 compatible WinCC OA webclient.',
    para: 'Engineering (Para)',
    hdb: 'internal (HDB / NGA Basis)',
    hdbtooltip:
      'Historical DataBase / Next Generation Archiver. Standard archiving solution of WinCC OA.',
    rdb: 'Oracle (RDB)',
    rdbtooltip:
      'Relational Database add-on for connection of an Oracle database to a WinCC OA Server.',
    nga: 'NGA',
    ngatooltip:
      'Next Generation Archiver. Archiving solution for WinCC OA for multiple databases (optional).',
    ngastd: 'NGA Standard (additional to NGA Basis)',
    ngaprem: 'NGA Premium',

    OA_IPC128: 'IPC 128',
    OA_IPC256: 'IPC 256',
    OA_IPC512: 'IPC 512',
    OA_IPC2048: 'IPC 2048',
    OA_IPC4096: 'IPC 4096',
    s7: 'S7+ (connections)',
    dnp: 'DNP3',
    dnptooltip: 'Distributed Network Protocol. Driver for DNP3 protocol for telecontrol.',
    sinaut: 'SINAUT',
    videoworkstation: 'Video Workstation',
    videobasis: 'Video Basis',
    videolight: 'Video Light',
    sinauttooltip:
      'Siemens Network AUTomation driver, enables for connecting with devices via the SINAUT protocol.',
    BACNET0: 'No BACNET',
    BACNET200: '200 BACNET Objects',
    BACNET500: '500 BACNET Objects',
    BACNET1000: '1000 BACNET Objects',
    BACNET3000: '3000 BACNET Objects',
    BACNET5000: '5000 BACNET Objects',
    drv: 'Connectivity',
    OA: 'WinCC OA System',
    ODB: 'Oracle database server',

    RP: 'Remote Engineering Station',
    modbus: 'Modbus TCP',
    profinet: 'PROFINET',
    profisafe: 'PROFIsafe',
    iec104: 'IEC 60870-5-104',
    iec101: 'IEC 60870-5-101',
    iec61850: 'IEC 61850/61400',
    saia: 'SAIA S-Bus',
    ssi: 'SSI',
    ssitooltip:
      'Synchronous Serial Interface. Driver for Ethernet communication via SAT telecontrol components',
    tls: 'TLS',
    tlstooltip:
      'Driver TLS („Technische Lieferbedingungen für Streckenstationen“ = technical delivery conditions for route stations). Gateway for controlling traffic control systems.',
    rk512: 'RK512',
    snmp: 'SNMP',
    cerberus: 'Cerberus',
    cerberustooltip: 'Driver for connection with the Siemens fire alarm system DMS7000 /Cerberus.',
    eip: 'EthernetIP',
    bacnet: 'BACnet',
    mqtt: 'MQTT',
    opt: 'Options',
    webserver: '# additional Webserver',
    ngaconnections: '# NGA Connections (additional)',
    commcenter: 'CommCenter (Alarms)',

    customcomp: '# Custom Components',
    dblogger: 'DB Logger',
    gismap: 'GIS/Map',
    topology: 'Topology Packet',
    modbussrv: 'Modbus Server',
    report: 'Report',
    ams: 'Advanced Maintenance Suite (AMS)',
    amstooltip: 'Advanced Maintenance Suite.',

    sscadakpi: 'SmartSCADA Toolbox (KPI)',
    sscadakpiext: 'SmartSCADA KPI Extension',
    sscadaana: 'SmartSCADA Analytics',
    secure: 'Secure by Kerberos',
    videosrc: 'Video (Cameras/Sources)',
    videobase: 'Video Base Package',
    V0: 'No video',
    V1: '1 source',
    V10: '10 sources',
    V25: '25 sources',
    V50: '50 sources',
    V100: '100 sources',
    V250: '250 sources',
    V500: '500 sources',
    videodisp: 'Video displays',
    videodisplaydist: 'Video Displays DIST',
    asfu: 'App Specific Full Use (ASFU)',
    fu: 'Full Use (FU)',
  },
};
let systemObj;
let archDetails;

function lt(nm) {
  return ltext[window.app.lang][nm];
}

function getFieldValue(ele) {
  let res = null;
  if (ele.tagName === 'WL-CHECKBOX') {
    res = ele.checked;
  } else if (ele.tagName === 'WL-RADIO') {
    res = ele.value;
  } else if (ele.type === 'number') {
    res = ele.valueAsNumber;
  } else if (ele.type === 'text') {
    res = ele.value;
  } else if (ele.type === 'date') {
    res = ele.valueAsDate;
  } else if (ele.type === 'select-one') {
    res = ele.value;
  }
  return res;
}

function checkUlc(e) {
  systemObj.setUlcUx(e.target.valueAsNumber);

  if (systemObj.ulcux > 30) {
    document.getElementsByName('loadbalancer')[0].value = systemObj.loadbalancer;
    window.app.architecture.initial = false;

    alert(lt('ulcwarn'));
  }
}

function checkDrs(e) {
  systemObj.toggleDrs(e.target.checked);
  window.app.architecture.initial = false;

  //update dom elements
  ['dist', 'redundant', 'drs'].forEach(elementName => {
    document.getElementsByName(elementName)[0].checked = systemObj[elementName];
  });
  if (e.target.checked) {
    document.getElementById('historian_rdb').checked = true;
  }

  this.requestUpdate();
}

function assign(e) {
  const ele = e.target;

  // disallow negative values on number fields
  if (ele.type === 'number' && ele.value < 0) {
    ele.value = 0;
  }
  if (ele.name) {
    systemObj[ele.name] = getFieldValue(ele);
    window.app.architecture.initial = false;
  }
  this.requestUpdate();
}

function checkZeroValue(e) {
  if (e.target.value === '') {
    //write value back to field
    e.target.value = 0;
  }
}

function calcIOs(e) {
  let sysios = e.target.valueAsNumber;
  if (e.target.value === '') {
    sysios = 0;
  }
  systemObj.setSysIos(sysios);

  e.srcElement.form.elements.licios.value =
    systemObj.licios === 99999999999 ? 'unlimited' : systemObj.licios;

  e.srcElement.form.elements.numios.value = systemObj.numios;
  if (systemObj.numios > 150000) {
    document.getElementById('ngalabel').innerHTML = lt('ngaprem');
  } else {
    document.getElementById('ngalabel').innerHTML = lt('ngastd');
  }

  window.app.architecture.initial = false;
}

function calcBacnets(e) {
  let bacnetValue = e.target.valueAsNumber;
  if (e.target.value === '') {
    bacnetValue = 0;
  }
  systemObj.setSysBacnets(bacnetValue);

  e.srcElement.form.elements.licbacnets.value =
    systemObj.licbacnets === 99999999999 ? 'unlimited' : systemObj.licbacnets;
  e.srcElement.form.elements.numbacnets.value = systemObj.numbacnets;

  systemObj.proto_std = 1;
  this.requestUpdate();

  window.app.architecture.initial = false;
}

function calcNumSys(e) {
  systemObj.setNum(e.target.valueAsNumber);

  e.srcElement.form.elements.licios.value = systemObj.licios;
  e.srcElement.form.elements.numios.value = systemObj.numios;

  if (systemObj.numios > 150000) {
    document.getElementById('ngalabel').innerHTML = lt('ngaprem');
  } else {
    document.getElementById('ngalabel').innerHTML = lt('ngastd');
  }

  window.app.architecture.initial = false;
  this.requestUpdate();
}

function onDistChange(e) {
  if (e.currentTarget.checked) {
    systemObj.enableDist();
  } else {
    systemObj.disableDist();
  }
  document.getElementById('videodisplaydistcontainer').style.display = systemObj.dist
    ? 'block'
    : 'none';
  document.getElementById('videodisplaydist').value = 0;
  window.app.architecture.initial = false;
}

function resetVisibility() {
  [
    'numlicblock',
    'sysiosfieldset',
    's7',
    'licios',
    'dist',
    'drs',
    'historian_hdb',
    'historian_rdb',
    'historian_nga',
  ].forEach(elementName => {
    document.getElementById(elementName).style.display = 'flex';
  });
}

function checkDongle(e) {
  const isChecked = e.target.checked;
  systemObj.dongle = isChecked;

  //deselect dongleMicro if dongle has been selected
  if (isChecked && systemObj.dongleMicro) {
    systemObj.dongleMicro = false;

    for (let i = 0; i < document.getElementsByName('dongleMicro').length; i += 1) {
      document.getElementsByName('dongleMicro')[i].checked = false;
    }
  }
}
function checkDongleMicro(e) {
  const isChecked = e.target.checked;
  systemObj.dongleMicro = isChecked;

  //deselect dongle if dongle has been selected
  if (isChecked && systemObj.dongle) {
    systemObj.dongle = false;

    for (let i = 0; i < document.getElementsByName('dongle').length; i += 1) {
      document.getElementsByName('dongle')[i].checked = false;
    }
  }
}

function onSystemTypeChange(e) {
  systemObj.setType(e.target.value);
  resetVisibility();

  archDetails.updateDistState(systemObj.bus.getRootBus());

  document.getElementById('sysios').value = systemObj.sysios;

  const ipc = document.getElementById('type-ipc');
  const oa = document.getElementById('type-oa');
  const odb = document.getElementById('type-odb');

  // switch ipc panels
  ipc.style.display = ['OA_IPC128', 'OA_IPC256', 'OA_IPC512', 'OA_IPC2048', 'RP'].includes(
    systemObj.type,
  )
    ? 'block'
    : 'none';

  //switch oa panels
  oa.style.display = ['apm', 'apm_sim', 'OA', 'OA_IPC4096'].includes(systemObj.type)
    ? 'block'
    : 'none';

  //switch odb panel
  odb.style.display = systemObj.type === 'ODB' ? 'block' : 'none';

  document.getElementById('sysios').value = systemObj.sysios;
  document.getElementsByName('subios')[0].value = systemObj.subios;
  document.getElementsByName('numios')[0].value = systemObj.numios;
  document.getElementsByName('licios')[0].value = systemObj.licios;

  // hide OA_IPC4096 fields
  if (systemObj.type === 'OA_IPC4096') {
    [
      'numlicblock',
      's7',
      'licios',
      'dist',
      'drs',
      'historian_hdb',
      'historian_rdb',
      'historian_nga',
    ].forEach(elementName => {
      document.getElementById(elementName).style.display = 'none';
    });
  }

  //apm specific settings
  if (systemObj.type === 'apm') {
    //hide sysiosfieldset panel
    document.getElementById('sysiosfieldset').style.display = 'none';
  }
  if (systemObj.type === 'apm' || systemObj.type === 'apm_sim') {
    //hide dist and drs
    ['dist', 'drs'].forEach(elementName => {
      document.getElementById(elementName).style.display = 'none';
    });

    systemObj.historian === 'nga';

    document.getElementById('historian_hdb').style.display = 'none';
    document.getElementById('historian_rdb').style.display = 'none';
    document.getElementById('historian_nga').style.display = 'none';
  }

  window.app.architecture.initial = false;

  document.getElementById('ngaconnections').disabled =
    systemObj.historian === 'nga' ||
    systemObj.type === 'apm' ||
    systemObj.type === 'apm_sim' ||
    systemObj.type === 'OA_IPC4096'
      ? ''
      : 'true';

  if (systemObj.type === 'OA') {
    systemObj.ngaconnections = 0;
    document.getElementById('ngaconnections').value = systemObj.ngaconnections;
  }

  this.requestUpdate();
}

function onVideoChange(e) {
  const videoBasePackageEnabled = e.target.checked;
  //set video base package information
  systemObj.videobase = videoBasePackageEnabled;

  const videosrc = document.getElementById('videosrc');
  const videodisp = document.getElementById('videodisp');
  const videodisplaydist = document.getElementById('videodisplaydist');
  const videoworkstation = document.getElementById('videoworkstation');

  //enable or disable video options, based on selection of base package selection
  videosrc.disabled = !videoBasePackageEnabled;
  videodisp.disabled = !videoBasePackageEnabled;
  videoworkstation.disabled = !videoBasePackageEnabled;
  videodisplaydist.disabled = !videoBasePackageEnabled;

  //reset values if base package gets disabled
  if (videoBasePackageEnabled === false) {
    videosrc.value = 0;
    videodisp.value = 0;
    videodisplaydist.value = 0;
  }

  //disable video light
  systemObj.videolight = false;
  const videolight = document.getElementById('videolight');
  videolight.checked = false;

  window.app.architecture.initial = false;
}

function onVideoLightChange(e) {
  const videoLightPackageEnabled = e.target.checked;

  //assign to system
  systemObj.videolight = true;

  if (videoLightPackageEnabled === true) {
    //disable videobase

    systemObj.videobase = false;
    const videobase = document.getElementById('videobase');
    videobase.checked = false;

    //disable videoworkstation
    systemObj.videoworkstation = false;
    const videoworkstation = document.getElementById('videoworkstation');
    videoworkstation.checked = false;
    videoworkstation.disabled = 'disabled';

    //disable videodisplaydist
    systemObj.videodisplaydist = false;
    const videodisplaydist = document.getElementById('videodisplaydist');
    videodisplaydist.checked = false;
    videodisplaydist.disabled = 'disabled';

    //set videosrc to 0 and disable it
    systemObj.videosrc = 0;
    const videosrc = document.getElementById('videosrc');
    videosrc.value = 0;
    videosrc.disabled = true;

    //set videodisp to 0 and disable it
    systemObj.videodisp = 0;
    const videodisp = document.getElementById('videodisp');
    videodisp.value = 0;
    videodisp.disabled = true;
  } else {
    //re-eenable videobase
    const videobase = document.getElementById('videobase');
    videobase.disabled = '';

    //re-eenable videoworkstation
    const videoworkstation = document.getElementById('videoworkstation');
    videoworkstation.disabled = '';

    //re-eenable videodisplaydist
    const videodisplaydist = document.getElementById('videodisplaydist');
    videodisplaydist.disabled = '';
  }
  window.app.architecture.initial = false;
  this.requestUpdate();
}

/**
 *
 * @param _archDetails
 * @param system
 */
export function SystemDetailDlg(_archDetails, system) {
  const env = { this: archDetails, data: system };
  systemObj = system;
  archDetails = _archDetails;
  const rpVisible = system.bus.parentId ? 'display:none' : '';

  return html`
      <div slot="content">
          <form id="detailform" method="dialog" class="grid3">
              <fieldset style="display:flex;margin-bottom:20px;;">
                  <legend>${lt('sys')}</legend>
                  <div style="display:flex;width:100%;">
                      <div style="display:block; flex:4; margin: 3px;">
                          <wl-select
                                  data-sys-id="${system.id}"
                                  name="type"
                                  @input="${onSystemTypeChange}"
                                  label="${lt('type')}"
                                  value="${system.type || 0}"
                          >
                              <option value="OA">${lt('OA')}</option>
                              <option value="OA_IPC128">${lt('OA_IPC128')}</option>
                              <option value="OA_IPC256">${lt('OA_IPC256')}</option>
                              <option value="OA_IPC512">${lt('OA_IPC512')}</option>
                              <option value="OA_IPC2048">${lt('OA_IPC2048')}</option>
                              <option value="OA_IPC4096">${lt('OA_IPC4096')}</option>

                              <option value="apm">${lt('apm_system')}</option>
                              <option value="apm_sim">${lt('apm_system_sim')}</option>
                              <option value="ODB">${lt('ODB')}</option>
                              <option value="RP" style="${rpVisible}">${lt('RP')}</option>
                          </wl-select>
                      </div>
                      <div style="display:block; flex:3;margin:3px;">
                          <wl-textfield
                                  @input="${assign}"
                                  name="name"
                                  label="${lt('name')}"
                                  value="${system.name || ''}"
                          ></wl-textfield>
                      </div>
                      <div style="display:block; flex:1;margin:3px;">
                          <wl-textfield
                                  @input="${calcNumSys}"
                                  type="number"
                                  min="0"
                                  name="num"
                                  label="${lt('num')}"
                                  value="${system.num || 1}"
                          ></wl-textfield>
                      </div>
                  </div>
              </fieldset>
              <div
                      id="type-ipc"
                      style="display:${
                        ['OA_IPC128', 'OA_IPC256', 'OA_IPC512', 'OA_IPC2048', 'RP'].includes(
                          system.type,
                        )
                          ? 'block'
                          : 'none'
                      }"
              >
                  <fieldset style="display:flex;margin-bottom:20px;;">
                      <legend>${lt('ios')}</legend>
                      <div style="display:flex;flex-wrap: wrap;width: 100%;">
                          <div style="display:block; flex: 1; margin: 3px;" id="dongle">
                              <wl-label>
                                  <wl-checkbox
                                          value="dongle"
                                          name="dongle"
                                          @change="${checkDongle}" .checked="${
    system.dongle || false
  }"
                                  ></wl-checkbox>
                                  ${lt('dongle')}
                              </wl-label>
                          </div>

                          <div style="display:block; flex: 1; margin: 3px;" id="dongleMicro">
                              <wl-label>
                                  <wl-checkbox
                                          value="dongleMicro"
                                          name="dongleMicro"
                                          @change="${checkDongleMicro}" .checked="${
    system.dongleMicro || false
  }"
                                  ></wl-checkbox>
                                  ${lt('dongleMicro')}
                              </wl-label>
                          </div>
                      </div>
                  </fieldset>
              </div>
              <div
                      id="type-oa"
                      style="display:${
                        ['apm', 'apm_sim', 'OA', 'OA_IPC4096'].includes(system.type)
                          ? 'block'
                          : 'none'
                      }"
              >
                  <fieldset
                          style="${
                            ['apm_sim', 'OA', 'OA_IPC4096'].includes(system.type)
                              ? 'display:flex;margin-bottom:20px;'
                              : 'display:none'
                          }"
                          id="sysiosfieldset">
                      <legend>${lt('ios')}</legend>

                      <div style="display:flex;flex-wrap: wrap;width: 100%;">
                          <div
                                  style="display:${
                                    system.type === 'OA' || system.type === 'apm_sim'
                                      ? 'flex'
                                      : 'none'
                                  }; width: 100%; margin: 3px;"
                                  id="numlicblock"
                          >
                              <div style="display:block; flex-basis: 30%; margin:3px;">
                                  <wl-textfield
                                          @input="${calcIOs}"
                                          @change="${checkZeroValue}"
                                          type="number"
                                          min="0"
                                          id="sysios"
                                          name="sysios"
                                          label="${lt('sysios')}"
                                          value="${system.sysios || 0}"
                                  ></wl-textfield>
                              </div>
                              <div style="display:block; flex:1;margin:3px;" id="subios">
                                  <wl-textfield
                                          type="text"
                                          min="0"
                                          name="subios"
                                          label="${lt('subios')}"
                                          value="${
                                            system.subios === 99999999999
                                              ? 'unlimited'
                                              : system.subios
                                          }"
                                          disabled
                                  ></wl-textfield>
                              </div>
                              <div style="display:block; flex:1;margin:3px;" id="numios">
                                  <wl-textfield
                                          type="text"
                                          min="0"
                                          name="numios"
                                          label="${lt('numios')}"
                                          value="${
                                            system.numios === 99999999999
                                              ? 'unlimited'
                                              : system.numios
                                          }"
                                          disabled
                                  ></wl-textfield>
                              </div>
                              <div style="display:block; flex:1;margin:3px;" id="licios">
                                  <wl-textfield
                                          type="text"
                                          name="licios"
                                          label="${lt('licios')}"
                                          value="${
                                            system.licios === 99999999999
                                              ? 'unlimited'
                                              : system.licios
                                          }"
                                          disabled
                                  ></wl-textfield>
                              </div>
                          </div>

                          <!-- bacnet -->
                          <div
                                  style="display:${
                                    system.type === 'OA' || system.type === 'apm_sim'
                                      ? 'flex'
                                      : 'none'
                                  }; width: 100%; margin: 3px;"
                                  id="numbacnetblock"
                          >
                              <div style="display:block; flex-basis: 30%; margin:3px;" id="sysbacnets">
                                  <wl-textfield
                                          @input="${calcBacnets}"
                                          @change="${checkZeroValue}"
                                          type="number"
                                          min="0"
                                          name="sysbacnets"
                                          label="${lt('sysbacnets')}"
                                          value="${system.sysbacnets || 0}"
                                  ></wl-textfield>
                              </div>
                              <div style="display:block; flex:1;margin:3px;" id="subbacnets">
                                  <wl-textfield
                                          type="text"
                                          min="0"
                                          name="subbacnets"
                                          label="${lt('subbacnets')}"
                                          value="${
                                            system.subbacnets === 99999999999
                                              ? 'unlimited'
                                              : system.subbacnets
                                          }"
                                          disabled
                                  ></wl-textfield>
                              </div>
                              <div style="display:block; flex:1;margin:3px;" id="numbacnets">
                                  <wl-textfield
                                          type="text"
                                          min="0"
                                          name="numbacnets"
                                          label="${lt('numbacnets')}"
                                          value="${
                                            system.numbacnets === 99999999999
                                              ? 'unlimited'
                                              : system.numbacnets
                                          }"
                                          disabled
                                  ></wl-textfield>
                              </div>
                              <div style="display:block; flex:1;margin:3px;" id="licbacnets">
                                  <wl-textfield
                                          type="text"
                                          name="licbacnets"
                                          label="${lt('licbacnets')}"
                                          value="${
                                            system.licbacnets === 99999999999
                                              ? 'unlimited'
                                              : system.licbacnets
                                          }"
                                          disabled
                                  ></wl-textfield>
                              </div>
                          </div>
                          <div style="display:flex; width: 100%; margin: 3px;">
                              <div style="display:block; flex: 1; margin: 3px;" id="redundant">
                                  <wl-label>
                                      <wl-checkbox
                                              name="redundant"
                                              @change="${assign}"
                                              .checked="${system.redundant || false}"
                                      ></wl-checkbox>
                                      ${lt('redundant')}
                                  </wl-label>
                              </div>
                              <div
                                      style="display:${
                                        system.type === 'OA' ? 'block' : 'none'
                                      }; flex: 1; margin: 3px;"
                                      id="dist"
                              >
                                  <wl-label>
                                      <wl-checkbox
                                              name="dist"
                                              @change="${onDistChange}"
                                              .checked="${system.dist || false}"
                                      ></wl-checkbox>
                                      ${lt('dist')}
                                  </wl-label>
                              </div>
                              <div
                                      style="display:${
                                        system.type === 'OA' ? 'block' : 'none'
                                      }; flex: 1; margin: 3px;"
                                      id="drs"
                              >
                                  <wl-label>
                                      <wl-checkbox
                                              name="drs"
                                              @change="${checkDrs}"
                                              .checked="${system.drs || false}"
                                      ></wl-checkbox>
                                      ${lt('drs')}
                                  </wl-label>
                              </div>
                              <div style="display:block; flex: 1; margin: 3px;" id="dongle">
                                  <wl-label>
                                      <wl-checkbox
                                              value="dongle"
                                              name="dongle"
                                              @change="${checkDongle}"
                                              .checked="${system.dongle || false}"
                                      ></wl-checkbox>
                                      ${lt('dongle')}
                                  </wl-label>
                              </div>

                              <div style="display:block; flex: 1; margin: 3px;" id="dongleMicro">
                                  <wl-label>
                                      <wl-checkbox
                                              value="dongleMicro"
                                              name="dongleMicro"
                                              @change="${checkDongleMicro}"
                                              .checked="${system.dongleMicro || false}"
                                      ></wl-checkbox>
                                      ${lt('dongleMicro')}
                                  </wl-label>
                              </div>
                          </div>
                      </div>
                  </fieldset>

                  <fieldset style="margin-bottom:20px;;">
                      <legend>${lt('operatingstations')}</legend>
                      <div style="display:flex;gap:24px;width:100%;">
                          <div style="flex:1;border:2px solid grey;padding:12px;">
                              <strong>UI Client</strong>
                              <div style="display:block; flex-basis: 30%; margin:3px;">
                                  <wl-textfield

                                          type="number"
                                          min="0"
                                          name="uiclient"
                                          @change="${assign}"
                                          label="${lt('uiclient')}"
                                          value="${system.uiclient || 0}"
                                  ></wl-textfield>
                                  <div style="margin-top:12px;">
                                      ${lt('includes')}:
                                      <ul>
                                          <li>${lt('ui')}</li>
                                          <li>${lt('ulcux')}</li>
                                          <li>${lt('dashboardrw')}</li>


                                  </div>
                              </div>
                          </div>
                          <div style="flex:1;border:2px solid grey;padding:12px;">

                              <strong>UI Light</strong>
                              <div style="display:block; flex-basis: 30%; margin:3px;">
                                  <wl-textfield

                                          type="number"
                                          min="0"
                                          name="uilight"
                                          label="${lt('uilight')}"
                                          @change="${assign}"
                                          value="${system.uilight || 0}"
                                  ></wl-textfield>
                                  <div style="margin-top:12px;">
                                      ${lt('includes')}:
                                      <ul>
                                          <li>${lt('dashboardro')}</li>
                                          <li>${lt('dashboardmobile')}</li>
                                          <li>${lt('mobileui')}</li>
                                  </div>
                              </div>


                          </div>
                      </div>

                      <div style="display:block; flex:1;margin:3px;">
                          <wl-textfield
                                  @input="${assign}"
                                  type="number"
                                  min="0"
                                  name="para"
                                  label="${lt('para')}"
                                  value="${system.para || 0}"
                          ></wl-textfield>
                      </div>
                  </fieldset>

                  <fieldset style="display:${
                    ['apm', 'apm_sim', 'OA', 'OA_IPC4096'].includes(system.type) ? 'flex' : 'none'
                  };margin-bottom:20px;; flex-wrap: wrap;">
                      <legend>${lt('his')}</legend>
                      <div style="display:flex; width: 100%; margin: 3px;">
                          <div
                                  id="historian_hdb"
                                  style="flex:1;margin:3px;display:${
                                    ['OA', 'OA_IPC4096'].includes(system.type) ? 'flex' : 'none'
                                  }"
                          >
                              <wl-label

                              >
                                  <wl-radio

                                          name="historian"
                                          value="hdb"

                                          @change="${archDetails.checkHistorian.bind(env)}"
                                          .checked="${system.historian === 'hdb' || false}"
                                  ></wl-radio>
                                  ${lt('hdb')}
                              </wl-label>
                          </div>
                          <div
                                  id="historian_rdb"

                                  style="flex:1;margin:3px;display:${
                                    ['OA', 'OA_IPC4096'].includes(system.type) ? 'flex' : 'none'
                                  }"
                          >
                              <wl-label

                              >
                                  <wl-radio
                                          name="historian"
                                          value="rdb"

                                          @change="${archDetails.checkHistorian.bind(env)}"
                                          .checked="${system.historian === 'rdb' || false}"
                                  ></wl-radio>
                                  ${lt('rdb')}
                              </wl-label>
                          </div>
                      </div>

                      <div style="display:flex; width: 100%; margin: 3px;">
                          <div
                                  id="historian_nga"

                                  style="flex:1;margin:3px;display:${
                                    ['OA', 'OA_IPC4096'].includes(system.type) ? 'flex' : 'none'
                                  }"
                          >
                              <wl-label

                              >
                                  <wl-radio
                                          name="historian"
                                          value="nga"

                                          @change="${archDetails.checkHistorian.bind(env)}"
                                          .checked="${system.historian === 'nga' || false}"
                                  ></wl-radio>
                                  <span id="ngalabel"> ${lt(archDetails.getNgaLabel(env))} </span>
                              </wl-label>
                          </div>
                          <div style="display:block; flex:1;margin:3px;">
                              <wl-textfield
                                      .disabled="${
                                        system.historian === 'nga' ||
                                        system.type === 'OA_IPC4096' ||
                                        system.type === 'apm' ||
                                        system.type === 'apm_sim'
                                          ? ''
                                          : 'true'
                                      }"
                                      @input="${assign}"
                                      type="number"
                                      min="0"
                                      id="ngaconnections"
                                      name="ngaconnections"
                                      label="${lt('ngaconnections')}"
                                      value="${system.ngaconnections || 0}"
                              ></wl-textfield>
                          </div>
                      </div>
                  </fieldset>
                  <fieldset style="display:flex;margin-bottom:20px;; flex-wrap: wrap;">
                      <legend>${lt('drv')}</legend>
                      <div style="display:flex;width: 100%; margin: 3px;">
                          <div
                                  style="display:${
                                    system.type === 'OA' ? 'block' : 'none'
                                  }; flex: 1; margin: 3px;"
                                  id="s7"
                          >
                              <wl-select
                                      name="s7"
                                      @input="${assign}"
                                      label="${lt('s7')}"
                                      value="${system.s7 || 0}"
                              >
                                  <option value=""></option>
                                  <option value="64">64 connections</option>
                                  <option value="512">512 connections</option>
                              </wl-select>
                          </div>
                      </div>

                      <div style="display:flex;gap:24px;width:100%;">
                          <div style="flex:1;border:2px solid grey;padding:12px;">

                              <strong>${lt('proto_std')}</strong>
                              <div style="display:block; flex-basis: 30%; margin:3px;">
                                  <wl-textfield

                                          type="number"
                                          min="0"
                                          name="proto_std"
                                          label="${lt('proto_std')}"
                                          @change="${assign}"
                                          value="${system.proto_std || 0}"
                                  ></wl-textfield>
                                  <div style="margin-top:12px;">

                                      ${lt('includes')}:
                                      <div style="display:flex">
                                          <ul style="flex:1">
                                              <li>${lt('iec101')}</li>
                                              <li>${lt('modbus')}</li>
                                              <li>${lt('mqtt')}</li>
                                              <li>${lt('profinet')}</li>
                                              <li>${lt('omronfins')}</li>
                                              <li>${lt('eip')}</li>
                                              <li>${lt('rk512')}</li>

                                          </ul>

                                          <ul style="flex:1">
                                              <li>${lt('saia')}</li>
                                              <li>${lt('snmp')}</li>
                                              <li>${lt('ssi')}</li>
                                              <li>${lt('bacnet')}</li>
                                              <li>${lt('opcuaserver')}</li>
                                              <li>${lt('mindsphere')}</li>
                                              <li>${lt('modbusserver')}</li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>


                          </div>
                          <div style="flex:1;border:2px solid grey;padding:12px;">
                              <strong>${lt('proto_prem')}</strong>
                              <div style="display:block; flex-basis: 30%; margin:3px;">
                                  <wl-textfield

                                          type="number"
                                          min="0"
                                          name="proto_prem"
                                          @change="${assign}"
                                          label="${lt('proto_prem')}"
                                          value="${system.proto_prem || 0}"
                                  ></wl-textfield>
                                  <div style="margin-top:12px;">

                                      ${lt('includes')}:
                                      <ul>
                                          <li>${lt('iec104')}</li>
                                          <li>${lt('iec61850')}</li>
                                          <li>${lt('dnp')}</li>
                                          <li>${lt('profisafe')}</li>
                                          <li>${lt('sinaut')}</li>
                                          <li>${lt('tls')}</li>
                                          <li>${lt('ntcip')}</li>


                                      </ul>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </fieldset>
                  <fieldset style="display:flex;margin-bottom:20px;; flex-wrap: wrap; ">
                      <legend>${lt('opt')}</legend>
                      <div style="display:flex; width: 100%; margin: 3px; flex-wrap: wrap;">
                          <div style="display:block; flex:1;margin:3px;">
                              <wl-textfield
                                      @input="${assign}"
                                      type="number"
                                      min="0"
                                      name="loadbalancer"
                                      label="${lt('loadbalancer')}"
                                      value="${system.loadbalancer || 0}"
                              ></wl-textfield>
                          </div>
                          <div style="display:block; flex:1;margin:3px;">
                              <wl-textfield
                                      @input="${assign}"
                                      type="number"
                                      min="0"
                                      name="websocket"
                                      label="${lt('websocket')}"
                                      value="${system.websocket || 0}"
                              ></wl-textfield>
                          </div>

                      </div>
                      <div style="display:flex; width: 100%; margin: 3px; flex-wrap: wrap;">


                          <div style="display:block; flex:1;margin:3px;">
                              <wl-textfield
                                      @input="${assign}"
                                      type="number"
                                      min="0"
                                      name="customcomp"
                                      label="${lt('customcomp')}"
                                      value="${system.customcomp || 0}"
                              ></wl-textfield>
                          </div>
                      </div>
                      <div style="display:flex; width: 100%; margin: 3px; flex-wrap: wrap;">
                          <div style="display:block; flex: 1; margin: 3px;">
                              <wl-label>
                                  <wl-checkbox
                                          name="dblogger"
                                          @change="${assign}"
                                          .checked="${system.dblogger || false}"
                                  ></wl-checkbox>
                                  ${lt('dblogger')}
                              </wl-label>
                          </div>
                          <div style="display:block; flex: 1; margin: 3px;">
                              <wl-label>
                                  <wl-checkbox
                                          name="gismap"
                                          @change="${assign}"
                                          .checked="${system.gismap || false}"
                                  ></wl-checkbox>
                                  ${lt('gismap')}
                              </wl-label>
                          </div>

                      </div>
                      <div style="display:flex; width: 100%; margin: 3px; flex-wrap: wrap;">


                          <div style="display:block; flex:1;margin:3px;">
                              <wl-label>
                                  <wl-checkbox
                                          name="topology"
                                          @change="${assign}"
                                          .checked="${system.topology || false}"
                                  ></wl-checkbox>
                                  ${lt('topology')}
                              </wl-label>
                          </div>
                          <div style="display:block; flex:1;margin:3px;">
                              <wl-label>
                                  <wl-checkbox
                                          name="ams"
                                          @change="${assign}"
                                          .checked="${system.ams || false}"
                                  ></wl-checkbox>
                                  ${lt('ams')}
                              </wl-label>
                          </div>
                      </div>


                      <div style="display:flex; width: 100%; margin: 3px; flex-wrap: wrap;">

                          <div style="display:block; flex:1;margin:3px;">
                              <wl-label>
                                  <wl-checkbox
                                          name="secure"
                                          @change="${assign}"
                                          .checked="${system.secure || false}"
                                  ></wl-checkbox>
                                  ${lt('secure')}
                              </wl-label>
                          </div>
                          <div style="display:block; flex:1;margin:3px;">
                              <wl-label>
                                  <wl-checkbox
                                          name="report"
                                          @change="${assign}"
                                          .checked="${system.report || false}"
                                  ></wl-checkbox>
                                  ${lt('report')}
                              </wl-label>
                          </div>

                      </div>
                      <!-- video row -->

                      <div style="display:flex; width: 100%; margin: 3px; flex-wrap: wrap;">
                          <div style="display:block; flex: 1; margin: 3px;">
                              <wl-label>
                                  <wl-checkbox
                                          id="videobase"
                                          name="videobase"
                                          @change="${onVideoChange}"
                                          .checked="${system.videobase || false}"
                                          .disabled="${system.videolight === true}"
                                  ></wl-checkbox>
                                  ${lt('videobasis')}
                              </wl-label>
                          </div>
                          <div style="display:block; flex: 1; margin: 3px;">
                              <wl-label>
                                  <wl-checkbox
                                          id="videoworkstation"
                                          name="videoworkstation"
                                          @change="${assign}"
                                          .checked="${system.videoworkstation || false}"
                                          .disabled="${system.videolight === true}"
                                  ></wl-checkbox>
                                  ${lt('videoworkstation')}
                              </wl-label>
                          </div>
                      </div>
                      <!-- video row  2-->
                      <div style="display:flex; width: 100%; margin: 3px; flex-wrap: wrap;">
                          <div style="display:block; flex: 1; margin: 3px;">
                              <wl-label>
                                  <wl-checkbox
                                          id="videolight"
                                          @change="${onVideoLightChange}"
                                          name="videolight"
                                          .checked="${system.videolight || false}"
                                  ></wl-checkbox>
                                  ${lt('videolight')}
                              </wl-label>
                          </div>
                          <div style="display:block; flex: 1; margin: 3px"></div>
                          <div style="display:block; flex: 1; margin: 3px;">
                              <wl-label>
                                  <wl-checkbox
                                          id="videodisplaydist"
                                          name="videodisplaydist"
                                          @change="${assign}"
                                          .checked="${system.videodisplaydist || false}"
                                          .disabled="${system.videolight === true}"
                                  ></wl-checkbox>
                                  ${lt('videodisplaydist')}
                              </wl-label>
                          </div>
                          <div style="display:block; flex: 1; margin: 3px;"></div>
                      </div>

                      <!-- comm center -->
                      <div style="display:flex; width: 100%; margin: 3px; flex-wrap: wrap;">


                          <div style="display:block; flex:1;margin:3px;">
                              <wl-label>
                                  <wl-checkbox
                                          name="commcenter"
                                          @change="${assign}"
                                          .checked="${system.commcenter || false}"
                                  ></wl-checkbox>
                                  ${lt('commcenter')}
                              </wl-label>
                          </div>

                      </div>


                      <!-- video row 3 -->
                      <div style="display:flex; width: 100%; margin: 3px; flex-wrap: wrap;">
                          <div style="display:block; flex: 1; margin: 3px;">
                              <wl-textfield
                                      @input="${assign}"
                                      type="number"
                                      min="0"
                                      name="videosrc"
                                      id="videosrc"
                                      .disabled="${system.videobase === false}"
                                      label="${lt('videosrc')}"
                                      value="${system.videosrc || 0}"
                              ></wl-textfield>
                          </div>
                          <div style="display:block; flex:1;margin:3px;">
                              <wl-textfield
                                      @input="${assign}"
                                      type="number"
                                      min="0"
                                      name="videodisp"
                                      id="videodisp"
                                      .disabled="${system.videobase === false}"
                                      label="${lt('videodisp')}"
                                      value="${system.videodisp || 0}"
                              ></wl-textfield>
                          </div>
                          <div
                                  id="videodisplaydistcontainer"
                                  style="${styleMap({
                                    display: system.dist ? 'block' : 'none',
                                    flex: 1,
                                    margin: '3px',
                                  })}"
                          >
                              <wl-textfield
                                      @input="${assign}"
                                      type="number"
                                      min="0"
                                      name="videodisplaydist"
                                      id="videodisplaydist"
                                      .disabled="${system.videobase === false}"
                                      label="${lt('videodisplaydist')}"
                                      value="${system.videodisplaydist || 0}"
                              ></wl-textfield>
                          </div>
                      </div>


                      <!-- scada row -->
                      <div
                              style="align-items:baseline;margin-top:15px; display:flex; width: 100%;  flex-wrap: wrap;"
                      >
                          <!-- scada kpi -->
                          <div style="display:block; flex:1;margin:3px;">
                              <wl-label>
                                  <wl-checkbox
                                          name="sscadakpi"
                                          @change="${assign}"
                                          .checked="${system.sscadakpi || false}"
                                  ></wl-checkbox>
                                  ${lt('sscadakpi')}
                              </wl-label>
                          </div>
                          <!-- scada ana -->
                          <div style="display:block; flex:1;margin:3px;">
                              <wl-label>
                                  <wl-checkbox
                                          name="sscadaana"
                                          @change="${assign}"
                                          .checked="${system.sscadaana || false}"
                                  ></wl-checkbox>
                                  ${lt('sscadaana')}
                              </wl-label>
                          </div>

                          <!-- scadakpiext -->
                          <div style="display:block; flex:1;margin:3px;">
                              <wl-textfield
                                      @input="${archDetails.checkScadaExt.bind(
                                        env,
                                        'sscadakpiext',
                                      )}"
                                      type="number"
                                      min="0"
                                      name="sscadakpiext"
                                      label="${lt('sscadakpiext')}"
                                      value="${system.sscadakpiext || 0}"
                              ></wl-textfield>
                          </div>
                      </div>


                  </fieldset>
              </div>
              <div id="type-odb" style="display:${system.type === 'ODB' ? 'block' : 'none'}">
                  <fieldset style="display:flex;margin-bottom:20px;;">
                      <legend>${lt('ios')}</legend>

                      <div style="display:flex; width: 100%; ">
                          <div style="display:block; flex: 2; margin: 3px;">
                              <wl-label>
                                  <wl-checkbox
                                          name="redundant"
                                          @change="${assign}"
                                          .checked="${system.redundant || false}"
                                  ></wl-checkbox>
                                  ${lt('redundant')}
                              </wl-label>
                          </div>

                          <div style="display:block;  flex:3;margin:3px;">
                              <wl-label>
                                  <wl-radio
                                          name="dblic"
                                          value="asfu"
                                          @change="${assign}"
                                          .checked="${system.dblic === 'asfu' || false}"
                                  ></wl-radio>
                                  ${lt('asfu')}
                              </wl-label>
                          </div>
                          <div style="display:block; flex:2;margin:3px;">
                              <wl-label>
                                  <wl-radio
                                          name="dblic"
                                          value="fu"
                                          @change="${assign}"
                                          .checked="${system.dblic === 'fu' || false}"
                                  ></wl-radio>
                                  ${lt('fu')}
                              </wl-label>
                          </div>
                      </div>
                  </fieldset>
              </div>

          </form>
          <div>
              <h2>Glossar</h2>
              <table class="glossar">
                  <tr>
                      <td class="short">ULC UX</td>
                      <td class="long">${lt('ulcuxtooltip')}</td>
                  </tr>
                  <tr>
                      <td class="short">HDB / NGA</td>
                      <td class="long">${lt('hdbtooltip')}</td>
                  </tr>
                  <tr>
                      <td class="short">NGA</td>
                      <td class="long">${lt('ngatooltip')}</td>
                  </tr>
                  <tr>
                      <td class="short">RDB</td>
                      <td class="long">${lt('rdbtooltip')}</td>
                  </tr>
                  <tr>
                      <td class="short">DNP3</td>
                      <td class="long">${lt('dnptooltip')}</td>
                  </tr>
                  <tr>
                      <td class="short">DRS</td>
                      <td class="long">${lt('drstooltip')}</td>
                  </tr>
                  <tr>
                      <td class="short">TLS</td>
                      <td class="long">${lt('tlstooltip')}</td>
                  </tr>
                  <tr>
                      <td class="short">SSI</td>
                      <td class="long">${lt('ssitooltip')}</td>
                  </tr>
                  <tr>
                      <td class="short">SINAUT</td>
                      <td class="long">${lt('sinauttooltip')}</td>
                  </tr>
                  <tr>
                      <td class="short">Cerberus</td>
                      <td class="long">${lt('cerberustooltip')}</td>
                  </tr>
                  <tr>
                      <td class="short">AMS</td>
                      <td class="long">${lt('amstooltip')}</td>
                  </tr>
                  <tr>
                      <td class="short">APM</td>
                      <td class="long">${lt('apmtooltip')}</td>
                  </tr>
              </table>
          </div>
      </div>
  `;
}
