import { css, html, LitElement } from 'lit-element';
import { showDialog } from 'weightless';
import { System } from '../../../System.js';
import { Bus } from '../../../Bus.js';
import { SystemDetailDlg } from './SystemDetailDlg.js';

export class ArchDetail extends LitElement {
  constructor() {
    super();
    if (
      !window.app ||
      !window.app.architecture ||
      !window.app.architecture.busses ||
      window.app.architecture.busses.length === 0
    ) {
      const s1 = new System('S1', { redundant: false, drs: false, dist: false, sysios: 0 });
      const b = new Bus();
      b.addPartner(s1);
      window.app.architecture.busses = [b];
      window.app.architecture.initial = true;
    }
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: column;
      }

      .divider {
        display: block;
        background-color: #aaa;
        width: 100%;
        height: 3px;
        margin-top: 8px;
      }

      .ovl {
        position: absolute;
        top: 0;
        left: 0;
        --width: 18px;
        --height: 18px;
        --button-fab-size: 24px;
      }

      .ovl small {
        font-size: 8px;
      }

      .ovl-o {
        position: absolute;
        top: -2px;
        left: 26px;
        width: 18px;
        height: 18px;
        margin: 0;
        font-size: 18px;
        text-align: center;
        color: var(--button-bg);
      }

      .ovl-u {
        position: absolute;
        top: 12px;
        left: 26px;
        width: 18px;
        height: 18px;
        margin: 0;
        font-size: 18px;
        text-align: center;
        color: var(--button-bg);
      }

      .system > .systemedit {
        visibility: hidden;
      }

      .system:hover > .systemedit {
        visibility: visible;
      }

      .ovl-u:hover {
        background-color: var(--button-bg);
        color: var(--button-color, #fff);
      }

      .ovl-o:hover {
        background-color: var(--button-bg);
        color: var(--button-color, #fff);
      }

      wl-button {
        --button-bg: var(--button-color);
      }

      wl-button.level {
        --button-bg: #aaa;
      }

      wl-button.levelremove {
        --button-bg: #f00;
        --icon-size: 20px;
        --button-fab-size: 20px;
      }

      wl-button.leveledit {
        --button-bg: grey;

        --icon-size: 40px;
        opacity: 0.7;
        --button-fab-size: 40px;
      }

      div.system {
        position: relative;
        text-align: center;
      }

      div.systemremove {
        position: absolute;
        bottom: 0px;
        left: 0px;
      }

      div.systemedit {
        position: absolute;
        bottom: 10px;
        left: 30px;
      }

      grid3 {
        display: flex;
      }

      gc3 {
        flex: 3;
      }

      gc1a {
        flex: 1;
      }

      gc1b {
        flex: 1;
      }

      gc1c {
        flex: 1;
      }

      div.fx {
        display: inline;
        flex: 1;
        margin: 3px;
      }
    `;
  }

  static get properties() {
    return {
      architecture: { type: Object },
      lang: { type: String },
    };
  }

  render() {
    return html` <div style="display:flex; width:100%;" id="renderme">${this.drawArch()}</div> `;
  }

  drawArchLevel(bus) {
    const ret = [];
    ret.push(html`
      <div style="display:flex; align-items:stretch; width:100%; flex-direction:row;">
        <div
          style="flex:1; margin-top:20px; width: 40px; flex-basis:40px; flex-grow:0; flex-shrink:0;"
        >
          <div>
            <wl-button data-bus-id="${bus.id}" fab @click="${this.addSystem}">
              <wl-icon>add</wl-icon>
            </wl-button>
          </div>
          <div>
            <wl-button
              data-bus-id="${bus.id}"
              fab
              @click="${this.addLevel}"
              style="margin-top:2px;"
            >
              <wl-icon>create_new_folder</wl-icon>
            </wl-button>
          </div>
        </div>
        <div style="position: relative; flex:1;  background-color:#ddd; margin:3px;">
          <div style="display:flex; justify-content: space-around; width: 100%;">
            ${bus.partners.map(item => this.drawSrv(item, bus))}
          </div>

          <div class="ovl">
            <wl-button fab flat inverted outlined disabled class="ovl">
              <small>&#10005;</small>${bus.num || 1}
            </wl-button>
            <a
              class="ovl-o"
              @click="${() => {
                bus.incBusNum();
                this.requestUpdate();
              }}"
            >
              +
            </a>
            <a
              class="ovl-u"
              @click="${() => {
                bus.decBusNum();
                this.requestUpdate();
              }}"
            >
              -
            </a>
          </div>

          ${bus.busses && bus.busses.length ? html` <div class="divider"></div> ` : ''}
          ${bus.busses && bus.busses.length
            ? html`
                <div style="flex:1;display:flex;">
                  ${bus.busses.map(
                    (b, i) => html`
                      <div
                        style="flex:1; background-color: white; display:flex; flex-direction: row; align-items:stretch;"
                      >
                        ${this.drawArchLevel(b)}
                      </div>
                    `,
                  )}
                </div>
              `
            : ''}
        </div>
      </div>
    `);

    return ret;
  }

  drawArch() {
    const ret = [];
    for (let i = 0; i < window.app.architecture.busses.length; i += 1) {
      ret.push(html`
        <div style="display:inline; flex:1; width:${100 / window.app.architecture.busses.length}%">
          <!--- div style="display:flex; width: 100%; align-items: stretch;" --->
          ${this.drawArchLevel(window.app.architecture.busses[i])}
          <!--- /div --->
        </div>
      `);
    }

    return ret;
  }

  drawSrv(srv, bus) {
    let img = null;
    switch (srv.type) {
      case 'OA':
      case 'apm':
      case 'apm_sim':
        img = srv.redundant ? 'ReduServer' : 'SingleServer';
        break;
      case 'OA_IPC128':
      case 'OA_IPC256':
      case 'OA_IPC512':
      case 'OA_IPC2048':
      case 'OA_IPC4096':
        img = 'ipc';
        break;
      case 'RP':
        img = 'RemotePara';
        break;
      case 'ODB':
        img = srv.redundant ? 'OracleDBCluster' : 'OracleDBServer';
        break;
      case 'IDB':
        img = 'InfluxDBServer';
        break;
      default:
    }

    return html`
      <div class="system">

        <img src="/media/${img}.svg"
             height="64px">
        ${(srv.num > 1 ? `${srv.num}x ` : '') + srv.name}
        </img>
        <div class="systemremove">
          <wl-button fab class="levelremove">
            <wl-icon id="${srv.id}" data-bus-id="${bus.id}" @click="${this.removeSystem}"
            >remove
            </wl-icon
            >
          </wl-button>
        </div>
        <div class="systemedit">
          <wl-button fab class="leveledit">
            <wl-icon id="${srv.id}" @click="${this.detailsPopup}">edit</wl-icon>
          </wl-button>
        </div>
      </div>
    `;
  }

  checkScadaExt(field, e) {
    //assign value
    this.data['sscadakpiext'] = parseInt(e.currentTarget.value);
    if (this.data['sscadakpiext'] > 0) {
      this.data['sscadakpi'] = true;
      document.getElementsByName('sscadakpi')[0].checked = true;
    }
  }

  getNgaLabel(env) {
    if (env.data && env.data.numios && env.data.numios > 150000) {
      return 'ngaprem';
    }
    return 'ngastd';
  }

  checkHistorian(e) {
    this.data.historian = e.currentTarget.value;
    if (this.data.historian === 'nga') {
      document.getElementsByName('ngaconnections')[0].disabled = false;
    } else {
      document.getElementsByName('ngaconnections')[0].disabled = true;
      document.getElementsByName('ngaconnections')[0].value = 0;
      this.data.ngaconnections = 0;
    }
  }

  async detailsPopup(e) {
    const systemId = e.currentTarget.id;

    const system = System.getSystemById(systemId);

    system.calcIOs();
    system.calcBacnets();

    showDialog({
      fixed: true,
      backdrop: true,
      blockScrolling: true,
      container: document.body,
      size: 'large',
      duration: 200,
      template: SystemDetailDlg(this, system),
    }).then(dlg => {
      dlg.result.then(() => {
        this.requestUpdate();
      });
      // e.srcElement.parentElement.parentElement.requestUpdate();
    });
  }

  updateDistState(bus) {
    const amountOaSystems = bus.getAmountOASystems(true);
    // if we have more than 1 oa system, enable dist on each oa system
    if (amountOaSystems > 1) {
      bus.getSystemsByType('OA', true).forEach(s => s.enableDist());
    } else if (amountOaSystems === 1) {
      // if we have only 1 oa system on current bus, check surrounding
      const surroundingOaSystems = bus.getSurroundingOaSystems(true);
      if (surroundingOaSystems.length > 1) {
        // ok on this bus we at least 2 oa systems. lets enable dist on all oa systems
        surroundingOaSystems.forEach(ss => ss.enableDist());
      } else if (surroundingOaSystems.length === 1) {
        // ok only 1 system found, disabled dist
        surroundingOaSystems[0].disableDist();
      }
    }
    bus.busses.forEach(b => this.updateDistState(b));
  }

  addSystem(e) {
    const bus = Bus.getBusById(e.currentTarget.getAttribute('data-bus-id'));
    if (!bus) {
      throw new Error('Bus not found');
    }
    bus.addPartner(new System(System.getNewSystemName()));
    this.updateDistState(bus.getRootBus());
    window.app.architecture.initial = false;
    this.requestUpdate().then(() => {});
  }

  removeSystem(e) {
    e.stopPropagation();
    const systemId = e.currentTarget.id;
    const bus = Bus.getBusById(e.currentTarget.getAttribute('data-bus-id'));
    if (!bus) {
      throw new Error(`Bus not found`);
    }
    bus.removePartner(systemId);

    this.updateDistState(bus.getRootBus());

    //if bus is empty, remove bus
    if (bus.partners.length === 0) {
      const parentBus = Bus.getBusById(bus.parentId);
      parentBus.removeBus(bus);
    }
    window.app.architecture.initial = false;
    this.requestUpdate().then(() => {});
  }

  addLevel(e) {
    const bus = Bus.getBusById(e.currentTarget.getAttribute('data-bus-id'));

    // create a new bus and append to existing bus
    const newBus = new Bus();
    bus.addBus(newBus);

    //  add a oa system to newly generated bus
    newBus.addPartner(new System(System.getNewSystemName(), {}));

    this.updateDistState(bus.getRootBus());
    window.app.architecture.initial = false;
    this.requestUpdate();
  }
}
