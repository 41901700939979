import { System } from '../../../System';
import { Bus } from '../../../Bus';

let system;
let sections;
let bus;
let logs = [];
let invalidLicenseOptions = [];
let report = '';
let shieldFile = {};

const ltext = {
  de: {
    bacnet: 'BACnet Option erkannt, bitte BACnet Objekte kontrollieren/einstellen',
    nameTooLong: 'System Name ist zu lange (%1) und wurde auf 27 Zeichen verkürzt',
    sumUiZero: 'Nicht eindeutige UI-Konfiguration. Werte für UI auf 0 gesetzt',
    sumParaZero: 'Nicht eindeutige Para-Konfiguration. Werte für Para auf 0 gesetzt',
  },
  en: {
    bacnet: 'BACnet Option found, please check/set BACnet Objects',
    nameTooLong: 'System name too long (%1) and has been shortened to 27 characters',
    sumUiZero: 'Ambigous UI configuration. Values for UI set to 0',
    sumParaZero: 'Ambigous Para configuration. Values for Para set to 0',
  },
};

function lt(nm) {
  return ltext[window.app.lang][nm];
}

export function loadIniFile(iniFileContent) {
  sections = parseIniToObjectArray(iniFileContent);
  return sections;
}

function parseIniToObjectArray(iniFileContent) {
  try {
    const lines = iniFileContent.split('\n');
    const objectArray = [];
    let currentSection = null;

    for (const line of lines) {
      const trimmedLine = line.trim();

      if (trimmedLine.startsWith('[') && trimmedLine.endsWith(']')) {
        if (currentSection) {
          objectArray.push(currentSection);
        }
        currentSection = {
          name: trimmedLine.slice(1, -1).trim(),
          items: [],
        };
      } else if (trimmedLine && !trimmedLine.startsWith(';')) {
        const [key, value] = trimmedLine.split('=').map(str => str.trim());
        currentSection.items.push({ name: key, value });
      }
    }

    if (currentSection) {
      objectArray.push(currentSection);
    }

    return objectArray;
  } catch (e) {
    console.error('unable to parse ini file');
    return false;
  }
}

/**
 * This function resets the system architecture.
 * It creates a new instance of the System class with the name 'S1' and certain properties.
 * It also creates a new Bus instance and adds the system as a partner to the bus.
 * The function then sets the architecture's busses to an array containing the new bus.
 * Finally, it sets the architecture's initial property to false.
 */

function resetArchitecture() {
  system = new System('S1', { redundant: false, drs: false, dist: false, sysios: 0 });
  bus = new Bus();
  bus.addPartner(system);
  window.app.architecture.busses = [bus];
  window.app.architecture.initial = false;
}

function getSection(sectionName) {
  return sections.find(e => e.name === sectionName);
}

export function getEntry(entryName, sectionName) {
  let entry = null;
  if (sectionName) {
    let section = getSection(sectionName);
    entry = section.items.find(e => e.name === entryName);
  } else {
    //search in all sections
    sections.forEach(section => {
      entry = section.items.find(e => e.name === entryName);
      if (entry) {
        return;
      }
    });
  }
  return entry;
}

function trimQuotes(str) {
  if (str.startsWith('"') && str.endsWith('"')) {
    return str.slice(1, -1);
  }
  return str;
}

export function getValue(entryName, sectionName) {
  const entry = getEntry(entryName, sectionName);
  if (entry) {
    return trimQuotes(entry.value);
  }
  return undefined;
}

export function getAmountOfSections(shieldFileContent) {
  return sections.length;
}

/**
 * This function logs a message with a key and value.
 *
 * @param {string} key - The key of the log entry.
 * @param {any} value - The value to be logged.
 * @param {string} message - The message to be logged.
 * @returns {void}
 */
function log(key, value, message = '', print = false) {
  if (print === true) {
    logs.push({ key, value, message });
  }
  console.log(key, value, message);
}

function isVersionSupported(currentVersion, checkVersion, supportRange) {
  // Direct comparison of major versions
  if (currentVersion.major !== checkVersion.major) {
    return false; // Different major versions are not supported
  }

  // Calculation of the supported minor version range
  const minSupportedMinor = currentVersion.minor - supportRange;

  // Checking if the checkVersion is within the supported range
  return checkVersion.minor >= minSupportedMinor && checkVersion.minor <= currentVersion.minor;
}

function parseProductVersion(versionStr) {
  // Extract the relevant parts of the version string
  const major = parseInt(versionStr.substring(0, 1), 10);
  const minor = parseInt(versionStr.substring(1, 3), 10);

  // Return the object with major and minor properties
  return { major: major, minor: minor };
}

export function parseShieldFile(section) {
  resetArchitecture();
  logs = [];
  invalidLicenseOptions = [];
  let suppressReportingLog = false;
  let uiSum = 0;
  let paraSum = 0;
  section.items.forEach(e => {
    let value = e.value;
    let key = e.name;
    switch (key) {
      case 'pararemote':
        //if pararemote value is 0 then do not add pararemote in any case.
        let pararemote = parseInt(getValue('pararemote', section.name) || undefined);
        if (pararemote === 0) {
          break;
        }

        let ios = parseInt(getValue('ios', section.name) || undefined);
        if (ios) {
          //if ios is set, add the para remote as seperate server to the configuration
          const s1 = new System('S1', {
            type: 'RP',
            redundant: false,
            drs: false,
            dist: false,
            sysios: 0,
          });

          //if dongle is activated, activate dongle on remotepara as well
          const codeValue = getValue('code', section.name);
          if (codeValue.includes('dongleHost')) {
            s1.dongle = true;
          }
          bus.addPartner(s1);
        } else {
          //otherwise, it's a para-remote configuration

          system.type = 'RP';
          system.redundant = false;
          system.drs = false;
          system.dist = false;
          system.sysios = 0;

          //if dongle is activated, activate dongle on remotepara as well
          const codeValue = getValue('code', section.name);
          if (codeValue.includes('dongleHost')) {
            system.dongle = true;
          }
        }

        break;
      case 'code':
        if (value.includes('dongleHost')) {
          system.dongle = true;
          log(key, value, "dongle has been selected, 'dongleHost' found");
        }
        break;
      case 'redundancy':
        if (parseInt(value) === 1) {
          system.redundant = true;
          log(key, value, 'set redundant to true');
        }
        break;
      case 'sqlviadpe':
        logs.push(
          key,
          value,
          'Wert kann nicht automatisch konfiguriert werden, Bitte Stückliste überprüfen',
        );
        break;
      case 'parafix':
        paraSum += parseInt(getValue('parafix', section.name) || 0);
        uiSum -= parseInt(getValue('parafix', section.name) || 0);
        break;
      case 'para':
        paraSum += parseInt(getValue('para', section.name) || 0);
        uiSum -= parseInt(getValue('para', section.name) || 0);
        break;
      case 'ui':
        uiSum += parseInt(getValue('ui', section.name) || 0);
        break;
      case 'uifix':
        uiSum += parseInt(getValue('uifix', section.name) || 0);
        break;
      case 'webclient':
        uiSum += parseInt(getValue('webclient', section.name) || 0);
        break;
      case 'javaui':
        uiSum += parseInt(getValue('javaui', section.name) || 0);
        break;
      case 'ultralight':
        uiSum += parseInt(getValue('ultralight', section.name) || 0);
        break;
      case 'api':
        system.customcomp = parseInt(value);
        log(key, value, 'Target field customcomp');
        break;
      case 'drvPlugin':
        const drvPluginValue = parseInt(getValue('drvPlugin', section.name));

        if (drvPluginValue > 0 && parseInt(getValue('api', section.name)) === 0) {
          system.customcomp = 1;
          log(key, value);
        }
        break;
      case 'ctrlext':
        const ctrlExtValue = parseInt(getValue('ctrlext', section.name));

        if (ctrlExtValue > 0 && parseInt(getValue('api', section.name)) === 0) {
          system.customcomp = 1;
          log(key, value);
        }
        break;
      case 'bacnet':
        if (parseInt(value) === 1) {
          system.proto_std++;
          log(key, value, lt('bacnet'), true);
        }
        break;
      case 'ios':
        system.sysios = parseInt(value);
        log(key, value, 'writing to ios');
        break;
      case 'distributed':
        if (parseInt(value) > 1) {
          system.dist = true;
          log(key, value, 'activating dist');
        }
        break;
      case 'gis':
        if (parseInt(value) > 0) {
          system.gismap = true;
          log(key, value);
        }
        break;
      case 'rdb_archive':
        if (parseInt(value) > 0) {
          system.historian = 'rdb';
          log(key, value, "Setting historian to ''rdb'");
        }

        break;
      case 'dis_rec_system':
        if (parseInt(value) === 1) {
          system.drs = true;
          log(key, value, 'activating drs');
        }
        break;
      case 'kerberos':
        if (parseInt(value) > 0) {
          system.secure = true;
          log(key, value, 'activating kerberos');
        }
        break;
      case 'ssi':
        if (parseInt(value) === 1) {
          system.ssi = true;
          system.proto_std++;
          log(key, value, 'activating ssi');
        }
        break;
      case 's7plus':
        system.s7 = value;
        log(key, value, 'Setting s7 plus connections');
        break;
      case 'iec101':
        if (parseInt(value) === 1) {
          system.proto_std++;
          log(key, value, 'activating iec101');
        }
        break;
      case 'dnp3':
        if (parseInt(value) === 1) {
          system.proto_prem++;
          log(key, value, 'activating dnp');
        }
        break;
      case 'tls':
        if (parseInt(value) === 1) {
          system.proto_prem++;
          log(key, value, 'activating tls');
        }
        break;
      case 'modbus':
        if (parseInt(value) === 1) {
          system.proto_std++;
          log(key, value, 'activating modbus');
        }
        break;
      case 'sinaut':
        if (parseInt(value) === 1) {
          system.proto_prem++;
          log(key, value, 'activating sinaut');
        }
        break;
      case 'modbus_srv':
        if (parseInt(value) === 1) {
          system.proto_std++;
          log(key, value, 'activating Modbus Server');
        }
        break;
      case 'iec104':
        if (parseInt(value) === 1) {
          system.proto_prem++;
          log(key, value, 'activating iec104');
        }
        break;

      case 'snmp':
        if (parseInt(value) === 1) {
          system.proto_std++;
          log(key, value, 'activating snmp');
        }
        break;
      case 'opc_ua_srv':
        if (parseInt(value) === 1) {
          system.proto_std++;
        }
        break;
      case 'apm':
        if (parseInt(value) === 1) {
          system.apm = true;
          log(key, value, 'activating apm');
        }
        break;
      case 'networktopo':
        if (parseInt(value) === 1) {
          system.topology = true;
          log(key, value, 'activating Network Topology');
        }
        break;
      case 'profisafe':
        if (parseInt(value) === 1) {
          system.proto_prem++;
          log(key, value, 'activating Profisafe');
        }
        break;
      case 'mqtt':
        if (parseInt(value) === 1) {
          system.proto_std++;
          log(key, value, 'activating MQTT');
        }
        break;
      case 'mindsphere':
        if (parseInt(value) === 1) {
          system.proto_std++;
          log(key, value, 'activating MindConnect');
        }
        break;
      case 'eip':
        if (parseInt(value) > 0) {
          system.proto_std++;
          log(key, value, 'Activating EIP');
        }
        break;
      case 'webserver':
        const webserverValue = parseInt(value) - 1;
        system.loadbalancer = webserverValue;
        log(key, value, 'Setting ULC Loadbalancer to ' + webserverValue);

        break;
      case 'smartscada_analyt':
        const smartScadaAnalytValue = parseInt(value);
        if (smartScadaAnalytValue !== 0) {
          system.sscadaana = true;
          log(key, value, 'Activating SmartScada Analytics');
        }
        break;
      case 'profinet':
        if (parseInt(value) > 0) {
          system.proto_std++;
          log(key, value, 'Activating Profinet');
        }
        break;
      case 'smartscada_kpi':
        const smartScadaValue = parseInt(value);
        if (smartScadaValue > 0) {
          system.sscadakpi = true;
          const smartScadaCalculatedValue = (smartScadaValue - 20) / 100;
          system.sscadakpiext = smartScadaCalculatedValue;
          log(
            key,
            value,
            'Activating SmartScada KPI & Setting ' +
              smartScadaCalculatedValue +
              ' to SmartScada KPI Extension',
          );
        }
        break;
      case 'mobile_ui':
        const mobileUiValue = parseInt(value);
        system.uilight += mobileUiValue;
        log(key, value, 'Adding MobileUI');
        break;
      case 'ams':
        const amsValue = parseInt(value);
        if (amsValue > 0) {
          system.ams = true;
          log(key, value, 'activating ams');
        }

        break;
      case 'infoserver':
        if (parseInt(value) > 1) {
          system.dblogger = true;
          log(key, value, 'activating DB Logger');
        }
        break;

      case 'iec61850':
        if (parseInt(value) === 1) {
          system.proto_prem++;
          log(key, value, 'activating iec61850');
        }
        break;
      case 'comcenter':
        const comcenterValue = parseInt(value);
        if (comcenterValue > 0) {
          system.commcenter = true; //yes, 2 m
          log(key, value, 'activating comcenter');
        }
        break;
      case 'video_display':
        const videoDisplayValue = parseInt(value);
        if (videoDisplayValue > 0) {
          system.videobase = true;
          system.videodisp = videoDisplayValue;
          log(key, value, 'Activated Video Base and Setting Video DIST to ' + value);
        }
        break;
      case 'video_vsource':
        const videoValue = parseInt(value);
        if (videoValue > 0) {
          system.videobase = true;
          system.videosrc = videoValue;
          log(key, value, 'Activated Video Base and Setting Video SRC to ' + value);
        }
        break;
      case 'excelreport':
      case 'reporting':
        const excelReport = parseInt(getValue('excelreport', section.name)) || 0;
        const reporting = parseInt(getValue('reporting', section.name)) || 0;
        system.report = excelReport || reporting;
        log(key, value, 'Set report to true');

        break;
      case 'sbus':
        if (parseInt(value) === 1) {
          system.proto_std++;
          log(key, value, 'activating saia');
        }
        break;
      case 'sn':
        const name = trimQuotes(value);
        if (name.length > 27) {
          system.name = name.substring(0, 27);
          log(key, value, lt('nameTooLong').replace('%1', name), true);
        } else {
          system.name = name;
          log(key, name, 'Setting SystemName to ' + name);
        }

        break;
      case 'version':
        //convert current version into object
        const versionStr = window.app.architecture.oaVersion.toString();
        const [major, minor] = versionStr.split('.').map(Number);
        const currentVersion = { major, minor };

        //convert version from shield-field into object
        const versionShieldFile = parseProductVersion(value);

        if (isVersionSupported(currentVersion, versionShieldFile, 4)) {
          window.app.architecture.license.license = 'upg';
        } else {
          window.app.architecture.license.license = 'lic';
        }
        break;
      //fields to ignore
      case '#hw':
      case 'expire':
      case 'scheduler':
      case 'maintenance':
      case 'recipe':
      case 'update':
      case 'demoPeriod':
      case 'event':
      case 'http':
      case 's7':
      case 'opc_hda_srv':
      case 'opc_hda':
      case 'mobile_app':
        break;
      default:
        invalidLicenseOptions.push({ key: key, value: value });

      //log(key, value, 'Unknown Key:' + key + ' (Value:' + value + ')', true);
    }
  });
  //do not allow negative values for uisum and parasum;
  if (uiSum < 0) {
    log('ui', '', lt('sumUiZero'), true);
    uiSum = 0;
  }
  if (paraSum < 0) {
    log('para', '', lt('sumParaZero'), true);
    paraSum = 0;
  }

  system.uiclient = uiSum;
  system.para = paraSum;

  return { logs: logs, invalidLicenseOptions: invalidLicenseOptions };
}
